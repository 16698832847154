<input #inputField matInput [formControl]="control" [placeholder]="placeholder" [matAutocomplete]="endpointAutoComplete" (focus)="fieldReceivedFocus()">
<mat-autocomplete #endpointAutoComplete="matAutocomplete" [autoActiveFirstOption]="true" (optionSelected)="selectEndpoint($event.option.value)">
    @if (filteredEndpoints(); as endpoints) {
        @if (!required && control.value === '' && value != null) {
            <mat-option [value]="null">-</mat-option>
        }
        @for (endpoint of endpoints; track endpoint.id) {
            <mat-option [value]="endpoint">
                <div>{{ endpoint.code }}</div>
                <div class="text-de-emphasized text-xs">{{ endpoint.name }}</div>
            </mat-option>
        }
    }
</mat-autocomplete>
