import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, Signal, signal, ViewChild } from "@angular/core";
import { AbstractMatFormFieldControl, createTextMatcher } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, merge } from "rxjs";
import { distinctUntilChanged, map, skip, takeUntil } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { EndpointId, FullRouteEndpointInfo } from "apina-frontend";
import { MatAutocomplete, MatAutocompleteModule } from "@angular/material/autocomplete";
import { toSignal } from "@angular/core/rxjs-interop";
import { RouteEndpointsService } from "./route-endpoints.service";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@Component({
    selector: "app-select-route-endpoint",
    templateUrl: "./select-route-endpoint.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: MatFormFieldControl, useExisting: SelectRouteEndpointComponent},
    ],
    standalone: true,
    imports: [
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    host: {
        '(focus)': 'focus()'
    },
})
export class SelectRouteEndpointComponent extends AbstractMatFormFieldControl<EndpointId> implements AfterViewInit {

    private static nextId = 0;

    readonly control = new FormControl<string>("", {nonNullable: true});
    readonly filteredEndpoints: Signal<FullRouteEndpointInfo[] | null>;

    private readonly selectedEndpointId = new BehaviorSubject<EndpointId | null>(null);

    private endpoints = signal<FullRouteEndpointInfo[] | null>(null);

    @ViewChild('inputField') inputField!: ElementRef;

    @ViewChild('endpointAutoComplete') endpointAutoComplete?: MatAutocomplete;

    /**
     * Is the autocomplete currently open?
     *
     * We'd like to use endpointAutoComplete.isOpen, but it still reports "open" when closing events arrive,
     * so we need to track state manually.
     */
    private _autoCompleteOpen = false;

    get value(): EndpointId | null {
        return this.selectedEndpointId.value;
    }

    set value(id: EndpointId | null) {
        if (id !== this.selectedEndpointId.value) {
            this.selectedEndpointId.next(id);
            this.updateName();
            this.stateChanges.next();
        }
    }

    constructor(routeEndpointsService: RouteEndpointsService) {
        super("app-select-route-endpoint", SelectRouteEndpointComponent.nextId++);

        routeEndpointsService.endpoints$.subscribe(data => {
            this.endpoints.set(data);
            this.updateName();
            this.stateChanges.next();
        });

        const valueChanges: Signal<string | undefined> = toSignal(this.control.valueChanges);

        this.filteredEndpoints = computed(() => {
            const filter = valueChanges();
            const endpoints = this.endpoints();
            if (typeof filter !== "string" || endpoints == null) return null;

            const matcher = createTextMatcher(filter);
            return endpoints.filter(it => matcher([it.code, it.name]));
        });

        this.stateChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            if (!this.focused)
                this.updateName();
        });
    }

    ngAfterViewInit(): void {
        merge(this.endpointAutoComplete!.opened.pipe(map(() => true)), this.endpointAutoComplete!.closed.pipe(map(() => false))).pipe(takeUntil(this.componentDestroyed$)).subscribe(open => {
            this._autoCompleteOpen = open;
            this.stateChanges.next();
        });
    }

    protected override get hasCustomFocus(): boolean {
        return this._autoCompleteOpen;
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    private updateName(): void {
        const id = this.value;
        if (id != null) {
            const endpoint = this.endpoints()?.find(it => it.id === id);
            if (endpoint != null)
                this.control.setValue(`${endpoint.code} ${endpoint.name}`);
        } else {
            this.control.setValue("");
        }
    }

    focus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.focus();
    }

    fieldReceivedFocus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.select();
    }

    selectEndpoint(endpoint: FullRouteEndpointInfo | null): void {
        if (endpoint != null) {
            this.value = endpoint.id;
            this.updateName();
        } else {
            this.value = null;
            this.updateName();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        // BehaviorSubject broadcasts the initial value, we skip it to avoid sending invalid change event
        this.selectedEndpointId.pipe(skip(1), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(fn);
    }
}
