import { Injectable } from "@angular/core";
import { FullRouteEndpointInfo, RouteEndpoint } from "apina-frontend";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

/**
 * Provide a service so that endpoints are cached between pickers.
 */
@Injectable({providedIn: "root"})
export class RouteEndpointsService {

    readonly endpoints$: Observable<FullRouteEndpointInfo[]>;

    constructor(routeEndpoint: RouteEndpoint) {
        this.endpoints$ = routeEndpoint.getEndpoints().pipe(shareReplay(1));
    }
}
